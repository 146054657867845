import React, { Component } from 'react';

import { withScrollingView } from '../../common/WithScrollingView';

class NotFound extends Component {
  componentDidMount() {
    document.title = 'Page Not Found | Burrow';
  }

  render() {
    return (
      <div className="notFound">
        <h1>OOPS!</h1>
        <p>The page you are looking for was moved, removed, renamed or lost - just like <a href="https://www.quora.com/Estimates-How-much-money-is-currently-lost-in-couches-across-America" target="blank">pennies in a sofa.</a></p>
      </div>
    );
  }
}

export default withScrollingView()(NotFound);
