import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getHrefLang } from '../../store/selectors/general.selectors';
import { withScrollingView } from '../../common/WithScrollingView';
import { HrefLang } from '../../types/schema';

const idsMap = {
  [HrefLang.EN_US]: 'friendbuyreferralpage',
  [HrefLang.EN_CA]: 'friendbuyreferralpageca',
};

class Referral extends PureComponent {
  componentDidMount() {
    if (window?.friendbuyAPI) {
      window.friendbuyAPI.push(['track', 'page', { name: 'referral' }]);
    }
  }

  render() {
    const { hrefLang } = this.props;
    return <div id={idsMap[hrefLang]} />;
  }
}

const mapStateToProps = (state) => ({
  hrefLang: getHrefLang(state),
});

Referral.defaultProps = {
  hrefLang: 'en-us',
};

Referral.propTypes = {
  hrefLang: PropTypes.string,
};

export default withScrollingView(mapStateToProps)(Referral);
