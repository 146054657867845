import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Formsy from 'formsy-react';

import Input from '../global/fields/Input';
import LoadingContainer from '../global/Loading';
import { withScrollingView } from '../../common/WithScrollingView';
import {
  forgotPassword,
  setCanForget,
} from '../../store/actions/user.actions';
import {
  getCanForget,
  getForgotPasswordFormMessage,
  getIsUserDataPending,
} from '../../store/selectors/user.selectors';
import { isLoading } from '../../../../utils/Utils';

class Forgot extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.disableButton = this.disableButton.bind(this);
  }

  onChange(state) {
    this.setState(state);
  }

  enableButton() {
    this.props.setCanForgetAction(true);
  }

  disableButton() {
    this.props.setCanForgetAction(false);
  }

  handleSubmit(data) {
    const { forgotPasswordAction } = this.props;
    forgotPasswordAction(data.email);
  }

  render() {
    const {
      canForget,
      forgotPasswordFormMessage,
      isUserDataPending,
    } = this.props;
    const disabled = isUserDataPending ? 'disabled' : '';

    return !isLoading(this.props) ? (
      <div className="auth forgot">
        <div className="auth-box">
          <h2 className="center">Forgot password?</h2>
          <p>No worries. Just enter your email and we’ll send you instructions to reset it.</p>
          <div className="auth-form form-fancy">
            <Formsy
              onValidSubmit={this.handleSubmit.bind(this)}
              onValid={this.enableButton}
              onInvalid={this.disableButton}
            >
              <div className="form-fancy-wrapper">
                <Input autocapitalize="none" name="email" validations="isEmail" validationError="Invalid Email" required label="Email" />
              </div>

              {forgotPasswordFormMessage && (
                <div className="auth-form">
                  <p>{forgotPasswordFormMessage}</p>
                </div>
              )}

              <div className="auth-form-wrapper">
                <div className="auth-form-col">
                  <button type="submit" disabled={!canForget} className={`btn btn-charcoal ${disabled}`}>Send me a reminder</button>
                </div>
                <div className="auth-form-col">
                  <p data-cy="forgot-password-nevermind"><Link to="login">Nevermind, remembered it!</Link></p>
                </div>
              </div>
            </Formsy>
          </div>
        </div>
      </div>
    ) : (
      <LoadingContainer />
    );
  }
}

const mapStateToProps = (state) => ({
  isUserDataPending: getIsUserDataPending(state),
  canForget: getCanForget(state),
  forgotPasswordFormMessage: getForgotPasswordFormMessage(state),
});

const mapDispatchToProps = {
  setCanForgetAction: setCanForget,
  forgotPasswordAction: forgotPassword,
};

export default withScrollingView(mapStateToProps, mapDispatchToProps)(Forgot);
