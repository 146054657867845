import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';
import { withScrollingView } from '../../common/WithScrollingView';
import { getQueryParams } from '../../store/selectors/router.selectors';
import { openCart } from '../../store/actions/ui.actions';
import { addToCart } from '../../store/actions/cart.actions';
import LoadingContainer from '../global/Loading';
import { getHrefLang } from '../../store/selectors/general.selectors';
import generateUrlWithHrefLang from '../../common/generateUrlWithHrefLang';
import paths from '../../paths';

class ShareCart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartAdded: false,
    };

    this.decodeCart = this.decodeCart.bind(this);
  }

  async componentDidMount() {
    const {
      addToCartAction,
      queryParams,
    } = this.props;

    const { overwrite = false } = queryParams;

    try {
      const decodedCart = this.decodeCart();
      if (Array.isArray(decodedCart) && decodedCart.length > 0) {
        const [firstItem, ...addOns] = decodedCart;
        const addOnItems = [];
        // If we're adding on to the cart of the current user
        if (addOns.length > 0) {
          for (let i = 0; i < addOns.length; i += 1) {
            const cartItem = addOns[i];
            addOnItems.push({
              quantity: cartItem.quantity,
              product: {
                id: cartItem.productId || cartItem.Id || null,
                optionValues: cartItem.optionSelections || [],
              },
            });
          }
        }
        await addToCartAction({
          productID: firstItem.productId,
        }, firstItem.optionSelections || [], firstItem.quantity || 1, 'shareCart', addOnItems, overwrite, 1);
      }
    } catch (e) { /* nothing to do */ }

    this.setState({
      cartAdded: true,
    });
  }

  static encodeProducts(cart) {
    const cartContents = JSON.stringify(cart);
    return encodeURIComponent(cartContents);
  }

  decodeCart() {
    const { queryParams } = this.props;
    const { cart = '' } = queryParams;
    if (cart) {
      const cartContents = decodeURIComponent(decodeURIComponent(cart));
      return JSON.parse(cartContents);
    }
    return [];
  }

  render() {
    // Redirect back to homepage
    const {
      hrefLang,
    } = this.props;
    if (this.state.cartAdded) {
      // Open the cart after the products have been added
      const { openCartAction } = this.props;
      openCartAction(true);
      return <Redirect push to={generateUrlWithHrefLang({ hrefLang, endpoint: paths.HOME })} />;
    }
    return <LoadingContainer />;
  }
}

const mapStateToProps = (state) => ({
  queryParams: getQueryParams(state),
  hrefLang: getHrefLang(state),
});

const mapDispatchToProps = {
  addToCartAction: addToCart,
  openCartAction: openCart,
};

export default withScrollingView(mapStateToProps, mapDispatchToProps)(ShareCart);
