import { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { replace } from 'connected-react-router';

import {
  getScrollHandlingComponentsAnchor,
  getNavItems,
  getExperiments,
} from '../store/selectors/ui.selectors';
import {
  getPageComponents,
} from '../store/selectors/page.selectors';

import Analytics from '../components/global/Analytics';
import {
  querySelector, getScrollingElement, getHeaderHeight, getLocationFields,
} from '../../../utils/Utils';

export const UTILITY_NAV_HEIGHT = 32;

export const getOffsetTop = (element) => {
  let offsetTop = 0;
  let nextElement = element;
  while (nextElement) {
    offsetTop += (nextElement.offsetTop || 0) - (nextElement.scrollTop || 0);
    nextElement = nextElement.offsetParent;
  }
  return Math.floor(offsetTop);
};

export const getScrollToValue = (anchor) => {
  const maxOffset = getScrollingElement().offsetHeight - window.innerHeight;
  const headerHeight = getHeaderHeight();
  const anchorOffset = getOffsetTop(anchor) - headerHeight;
  return Math.min(anchorOffset, maxOffset);
};

export const getScrollTop = () => window.scrollTop || window.scrollY;

export const scrollToTop = (callback) => {
  const onScroll = () => {
    if (getScrollTop() === 0) {
      document.removeEventListener('scroll', onScroll);
      if (typeof callback === 'function') {
        callback();
      }
    }
  };

  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

  document.addEventListener('scroll', onScroll);
  onScroll();

  return onScroll;
};

export const scrollToAnchor = (anchor, cb) => {
  let top = getScrollToValue(anchor);
  const scrollingUp = getScrollTop() > top;
  if (scrollingUp) {
    top -= UTILITY_NAV_HEIGHT;
  }

  anchor.scrollIntoView({
    behavior: 'smooth',
  });

  const onScroll = debounce(() => {
    const updatedTop = getScrollToValue(anchor) - (scrollingUp ? UTILITY_NAV_HEIGHT : 0);
    if (updatedTop !== top) {
      top = updatedTop;
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }

    if (getScrollTop() === top) {
      document.removeEventListener('scroll', onScroll);
      if (typeof cb === 'function') {
        cb(onScroll);
      }
    }
  }, 300);

  document.addEventListener('scroll', onScroll);
  onScroll();

  return onScroll;
};

const getDisplayName = (WrappedComponent) => `withScrollingView(${WrappedComponent.displayName
    || WrappedComponent.name
    || 'Component'})`;

// eslint-disable-next-line import/prefer-default-export
export const withScrollingView = (...reduxArgs) => (WrappedComponent) => {
  class WithScrollingView extends Component {
    targetScrollListeners = [];

    _isMounted = false;

    onPageComponentsChange = null;

    triggeredPageView = false;

    constructor(props) {
      super(props);

      this.unlockHashChangeHandler = this.unlockHashChangeHandler.bind(this);
      this.debouceAndUnlockHashChangeHandler = debounce(this.unlockHashChangeHandler, 700);
      this.lockHashChangeHandlerForAWhile = this.lockHashChangeHandlerForAWhile.bind(this);

      this.lockScrollHandler = this.lockScrollHandler.bind(this);
      this.unlockScrollHandler = this.unlockScrollHandler.bind(this);

      this.eraseTargetScrollListeners = this.eraseTargetScrollListeners.bind(this);

      this.handleHashChange = this.handleHashChange.bind(this);
      this.handleScroll = this.handleScroll.bind(this);
      this.handleWheelAndTouchmove = this.handleWheelAndTouchmove.bind(this);

      this.scrollToHash = this.scrollToHash.bind(this);
    }

    static handleAnalytics(experiments) {
      try {
        Analytics.enqueue({
          method: 'page',
          params: {
            experiments,
          },
          location: Analytics.getLocation(),
        });
      } catch (e) { /* nothing to do */ }
    }

    hashChangeHandlerLocked = false;

    scrollHandlerLocked = false;

    unlockHashChangeHandler() {
      this.hashChangeHandlerLocked = false;
    }

    lockHashChangeHandlerForAWhile() {
      this.hashChangeHandlerLocked = true;
      this.debouceAndUnlockHashChangeHandler();
    }

    unlockScrollHandler() {
      this.scrollHandlerLocked = false;
    }

    lockScrollHandler() {
      this.scrollHandlerLocked = true;
    }

    async scrollToHash(hash, cb) {
      let anchor = querySelector(hash);
      if (anchor) {
        return scrollToAnchor(anchor, cb);
      }
      // Wait for the page components change if anchor element is not present yet.
      // Such situation might occur when we redirect to a url with a hash.
      // The onPageComponentsChange should be called after page components change.
      await new Promise((resolve) => { this.onPageComponentsChange = resolve; });
      anchor = querySelector(hash);
      if (anchor && this._isMounted) {
        return scrollToAnchor(anchor, cb);
      }
      return null;
    }

    eraseTargetScrollListeners() {
      this.targetScrollListeners.forEach((scrollListener) => {
        document.removeEventListener('scroll', scrollListener);
      });
      this.targetScrollListeners = [];
    }

    async handleHashChange() {
      this.eraseTargetScrollListeners();
      const {
        match,
        location,
        nextApp,
        scrollHandlingComponent,
      } = this.props;

      const page = match?.isExact;
      const { hash } = getLocationFields(nextApp ? this.props.router : location);

      if (this.hashChangeHandlerLocked && hash) {
        return;
      }
      this.lockScrollHandler();

      const scrollCallback = (scrollListener) => {
        if (this.props.experiments) {
          WithScrollingView.handleAnalytics(this.props.experiments);
        } else {
          this.triggeredPageView = true;
        }
        this.targetScrollListeners = this.targetScrollListeners.filter((func) => func !== scrollListener);
        this.unlockScrollHandler();
      };

      if (!page && !nextApp) {
        return;
      }
      if (hash && !scrollHandlingComponent && !this.hashChangeHandlerLocked) {
        const scrollListener = await this.scrollToHash(hash, scrollCallback);
        if (scrollListener) {
          this.targetScrollListeners.push(scrollListener);
        }
        return;
      }
      if (!scrollHandlingComponent) {
        const scrollListener = scrollToTop(scrollCallback);
        this.targetScrollListeners.push(scrollListener);
        return;
      }
      scrollCallback();
    }

    handleWheelAndTouchmove() {
      this.eraseTargetScrollListeners();
      this.unlockScrollHandler();
    }

    handleScroll() {
      if (this.scrollHandlerLocked) {
        return;
      }
      this.lockHashChangeHandlerForAWhile();
      const {
        navItems,
        location,
        nextApp,
        replaceLocationAction,
      } = this.props;
      const scrollTop = getScrollTop();
      const {
        search,
        pathname,
      } = getLocationFields(nextApp ? this.props.router : location);

      const nextHash = navItems
        ?.map(({ slug }) => slug)
        .find((slug) => {
          const el = querySelector(`#${slug}`);
          if (!el) return null;
          const headerHeight = getHeaderHeight() + UTILITY_NAV_HEIGHT;
          const offsetTopIncludingHeader = getOffsetTop(el) - headerHeight;
          const { offsetHeight } = el;
          return offsetTopIncludingHeader <= scrollTop && scrollTop < offsetTopIncludingHeader + offsetHeight;
        });

      const parsedHash = nextHash ? `#${nextHash}` : '';
      if (parsedHash !== window.location.hash) {
        const urlToReplace = `${pathname}${search}${parsedHash}`;
        if (nextApp) {
          // related to: https://github.com/vercel/next.js/discussions/18072
          window.history.replaceState({
            ...window.history.state,
            as: urlToReplace,
          }, '', urlToReplace);

          // maybe this would not be need in app router:
          // https://nextjs.org/docs/app/building-your-application/routing/linking-and-navigating#using-the-native-history-api
          // eslint-disable-next-line react/destructuring-assignment, react/prop-types
          this.props.router.events.emit('hashChangeComplete', urlToReplace);
          return;
        }
        replaceLocationAction(urlToReplace);
      }
    }

    componentDidUpdate(prevProps) {
      const {
        nextApp,
        router,
        location,
        pageComponents,
        experiments,
      } = this.props;

      if (experiments && this.triggeredPageView) {
        WithScrollingView.handleAnalytics(experiments);
        this.triggeredPageView = false;
      }

      const {
        hash,
        pathname: path,
        search,
      } = getLocationFields(nextApp ? router : location);
      const {
        hash: prevHash,
        pathname: prevPath,
        search: prevSearch,
      } = getLocationFields(nextApp ? prevProps.router : prevProps.location);

      if (path && prevPath !== path) {
        this.unlockHashChangeHandler();
      }

      if ((hash && prevHash !== hash)
          || (path && prevPath !== path)
          || (search && prevSearch !== search)
      ) {
        this.handleHashChange();
      }

      // If there is a hash on a page, we need to scroll to it using this.handleHashChange().
      // This does not happen if the content is not rendered. So we,
      // 1. verify whether the content is rendered using querySelector(hash) and set the result to contentHasRendered
      let contentHasRendered = hash ? querySelector(hash) : true;

      // 2. if contentHasRendered is null (as is the case if content is not rendered), we create a MutationObserver, which
      // is an interface providing us with the ability to watch for changes being made to the DOM tree (in this case, completing of
      // the content rendering). More info - https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
      if (!contentHasRendered) {
        const observer = new MutationObserver((mutations, obs) => {
          contentHasRendered  = querySelector(hash);
          // 3. if the mutation observer detects contentHasRendered has become true (happens after the content has rendered), it will then run
          // this.handleHashChange() and disconnect itself (mutation observer wont be doing any more observing anymore)
          if (contentHasRendered) {
            this.handleHashChange();
            obs.disconnect();
          }
        });
        // tells the observer we created to start observing the target node for configured mutations
        observer.observe(document, {
          childList: true,
          subtree: true,
        });
      }

      if (pageComponents !== prevProps?.pageComponents && this.onPageComponentsChange) {
        this.onPageComponentsChange();
        this.onPageComponentsChange = null;
      }
    }

    componentDidMount() {
      this._isMounted = true;
      window.requestAnimationFrame(() => {
        this.handleHashChange();
        document.addEventListener('wheel', this.handleWheelAndTouchmove);
        document.addEventListener('touchmove', this.handleWheelAndTouchmove);
        document.addEventListener('scroll', this.handleScroll);
      });

      window.withScrollingViewContext = this;
    }

    componentWillUnmount() {
      this._isMounted = false;
      document.removeEventListener('wheel', this.handleWheelAndTouchmove);
      document.removeEventListener('touchmove', this.handleWheelAndTouchmove);
      document.removeEventListener('scroll', this.handleScroll);
      this.targetScrollListeners.forEach((scrollListener) => {
        document.removeEventListener('scroll', scrollListener);
      });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  WithScrollingView.displayName = getDisplayName(WrappedComponent);

  const [wrappedComponentMapStateToProps, wrappedComponentMapDispatchToProps] = reduxArgs;

  const mapStateToProps = (state) => ({
    ...(wrappedComponentMapStateToProps?.(state) || {}),
    scrollHandlingComponent: getScrollHandlingComponentsAnchor(state),
    experiments: getExperiments(state),
    navItems: getNavItems(state),
    pageComponents: getPageComponents(state),
    nextApp: state.ui?.nextApp,
  });

  const mapDispatchToProps = {
    ...(wrappedComponentMapDispatchToProps || {}),
    replaceLocationAction: replace,
  };

  return connect(mapStateToProps, mapDispatchToProps)(WithScrollingView);
};
